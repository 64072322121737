import React from "react";
import Translate from "../../config/Translate";
import "../../styles/PageHeader.css";

const PageHeader = ({ Title }) => {
  return (
    <div className="pageheader">
      <h1>
        <Translate Word={`${Title}`}></Translate>
      </h1>
      <div></div>
    </div>
  );
};
export default PageHeader;
