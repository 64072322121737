import React from "react";
import styled from "styled-components";
import Banner from "../assets/images/banner_right.jpg";
import BannerEvc from "../assets/images/evcBg.png";
import ResendMailContainer from "../components/ResendMailContainer";
const ResendMail = () => {
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  let hostname = window.location.hostname.split(".").slice(-2).join(".");
  let bgImage = Banner;
  if (hostname == "evc.clinic") {
    bgImage = BannerEvc;
  } else if (hostname == "vetmidi.org") {
    bgImage = Banner;
  } else {
    bgImage = Banner;
  }
  return (
    <Container style={{ backgroundImage: `url(${bgImage})` }}>
      <LeftContainer>
        <ResendMailContainer />
      </LeftContainer>
      {hostname != "evc.clinic" && <Overlay />}
      {hostname == "evc.clinic" && (
        <RightContainer
          style={{ backgroundImage: `url(${bgImage})` }}
        ></RightContainer>
      )}
    </Container>
  );
};

export default ResendMail;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  color: white;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000085;
`;

const LeftContainer = styled(Overlay)`
  width: 500px;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
  overflow-y: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
  @media screen and (max-width: 500px) {
    width: 100vw;
  }
`;

const RightContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 0;
  color: white;
  @media screen and (max-width: 500px) {
    width: 0;
  }
`;
