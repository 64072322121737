import secondLogo from "../assets/images/secondLogo.png";
import evc from "../assets/images/evc.png";
import dev from "../assets/images/dev.png";
let hostname = window.location.hostname.split(".").slice(-2).join(".");
export const companyInfo = () => {
  if (hostname == "vetmidi.org" || window.location.hostname == "vetmidi.org") {
    return {
      cname: "VETMIDI",
      CimagePath: secondLogo,
      cdescription: "Urgences vétérinaires",
    };
  } else if (
    hostname == "evc.clinic" ||
    window.location.hostname == "evc.clinic"
  ) {
    return {
      cname: "EVC",
      CimagePath: evc,
      cdescription: "Urgences vétérinaires",
    };
  } else {
    return {
      cname: "dev",
      CimagePath: dev,
      cdescription: "N/A",
    };
  }
};
