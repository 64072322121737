import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { UpdateUserPassword } from "../../features/ShowAccountSlice";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";
import ErrorBox from "../../helper/Messages/Error";
import { useForm } from "react-hook-form";
import Translate from "../../config/Translate";
import { AXIOS_POST } from "../../config/axios";
import { POST_CHANGE_PASSWORD_URL } from "../../helper/Url";
import { toast, ToastContainer } from "react-toastify";

const NewPassword = () => {
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hideRef = useRef();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [Errors, setErrors] = useState([]);

  const handleChangePassword = (data) => {
    AXIOS_POST(POST_CHANGE_PASSWORD_URL, data, token)
      .then((result) => {
        toast("Password Updated Successful!");
        setTimeout(() => {
          dispatch(UpdateUserPassword());
        }, 4000);
      })
      .catch((e) => {
        if (e.response.data.errors) {
          setErrors(e.response.data.errors);
        } else {
          setErrors(["Something went wrong"]);
        }
      });
  };
  const setFallBack = () => {
    dispatch(UpdateUserPassword());
    hideRef.current.style.display = "none";
  };

  return (
    <PopContainer ref={hideRef}>
      <ToastContainer />
      <Fade top>
        <ModalAction>
          <Top>
            <Title>
              <Translate Word={"page.profile.changePassword.btn"}></Translate>
            </Title>
            <Cross onClick={setFallBack}>
              <ClearIcon />
            </Cross>
          </Top>
          <Divider />

          <Form onSubmit={handleSubmit(handleChangePassword)}>
            <Label htmlFor="">
              <Redspan>*</Redspan>
              <Translate Word={"page.profile.existingPassword"} />
            </Label>
            <InputGroup
              type="password"
              {...register("current_password", {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              })}
              placeholder="... "
            />
            {errors?.current_password?.type === "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word={"errors.form.password.required"}></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}
            {errors?.current_password?.type == "pattern" ? (
              <Perror>
                <Fade bottom>
                  <Translate
                    Word={"errors.form.password.required.valid"}
                  ></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}

            <Label htmlFor="">
              <Redspan>*</Redspan>
              <Translate Word={"page.profile.password"} />
            </Label>
            <InputGroup
              type="password"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              })}
              placeholder="... "
            />
            {errors?.password?.type === "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word={"errors.form.password.required"}></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}
            {errors?.password?.type == "pattern" ? (
              <Perror>
                <Fade bottom>
                  <Translate
                    Word={"errors.form.password.required.valid"}
                  ></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}

            <Label htmlFor="">
              <Redspan>*</Redspan>
              <Translate Word={"page.profile.confirmPassword"} />
            </Label>
            <InputGroup
              {...register("password_confirmation", {
                validate: (value) => {
                  const { password } = getValues();
                  return password === value || "Passwords should match!";
                },
              })}
              type="password"
              placeholder="..."
            />
            {errors?.password_confirmation?.type == "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word={"errors.form.password.required"}></Translate>
                </Fade>
              </Perror>
            ) : (
              ""
            )}
            {errors?.password_confirmation?.type == "validate" ? (
              <Perror>
                <Fade bottom>
                  {" "}
                  <Translate Word={"errors.form.password.matching"}></Translate>
                </Fade>
              </Perror>
            ) : (
              ""
            )}

            <Divider />

            <FormAction>
              {/* <Button type="submit">
                {showLoader ? (
                  <Loader />
                ) : (
                  <Translate Word="page.singUpWelcome" />
                )}
              </Button> */}
              <Button type="submit">
                <Translate Word={"page.profile.confirmPassword.confirm"} />
              </Button>
            </FormAction>

            {Errors.map((e, i) => {
              return <ErrorBox key={i} Message={e?.message}></ErrorBox>;
            })}
          </Form>
          <Action></Action>
        </ModalAction>
      </Fade>
    </PopContainer>
  );
};
export default NewPassword;
const PopContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000075;
  display: flex;
  justify-content: center;
  z-index: 100000;
  overflow-y: auto;
`;
const Logo = styled.img`
  width: 300px;
  object-fit: contain;
`;
const Title = styled.h1`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  text-transform: uppercase;
  color: #79aec1;
`;
const Message = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 10px 0 20px 0px;
  font-size: 18px;
  text-align: justify;
`;
const Action = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;
const Button = styled.button`
  min-width: 80px;
  padding: 10px;
  border: none;
  background: #79aec1;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  background: #1890ff;
  border-color: #1890ff;
  margin-top: 10px;
  cursor: pointer;
`;
const ContinueBtn = styled(Button)`
  background: #79aec1;
  border-radius: 4px;
  font-weight: 600;
  color: white;
`;
const ModalAction = styled.div`
  min-height: 200px;
  width: 600px;
  background: whitesmoke;
  border-radius: 4px;
  border: none;
  margin-top: 200px;
  text-align: center;
  padding: 20px;
  @media (max-width: 650px) {
    width: 350px;
  }
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Cross = styled.div`
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px 5px;
  font-size: 13px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 9px;
  outline: none;
  margin-top: 5px;
  margin-bottom: 10px;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const InputGroup = styled(Input)`
  width: 100% !important;
`;
const Form = styled.form``;
const Perror = styled.div`
  color: #d03333e0;
  font-size: 14px;
`;
const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Label = styled.label`
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 17px;
  margin-bottom: 5px;
`;

const Redspan = styled.span`
  color: red;
  margin-right: 8px;
`;
