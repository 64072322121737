import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Translate from "../../../config/Translate";
import {
  GET_PETS_URL,
  GET_PROFILE_URL,
  UPDATE_PROFILE_URL,
} from "../../../helper/Url";
import { AXIOS_GET, AXIOS_PUT } from "../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { UserInfo } from "../../../features/getUserSlice";
import {
  ProfileAvatar,
  ProfileFname,
  ProfileLname,
  selectUpdatePassword,
  UpdateUserPassword,
} from "../../../features/ShowAccountSlice";
import PageLoader from "../../../helper/PageLoader";
import FirstTimePopUp from "../FirstTimePopUp";
import "./rightstyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "../NewPassword";
import { selectLanguage } from "../../../features/LanguageSlice";
import En from "../../../helper/Dictionary/En";
import Fr from "../../../helper/Dictionary/Fr";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import AnimalPopUp from "../AnimalPopUp";

const RightProfileForm = () => {
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  let hostname = window.location.hostname
    .split(".")
    .slice()
    .slice(-2)
    .join(".");
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const showUpdateForm = useSelector(selectUpdatePassword);
  const [ShowPop, SetPop] = useState(false);
  const [ShowAnimalPop, SetAnimalPop] = useState(false);
  const [isanimalavailable, setisanimalavailable] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const pinfo = useRef();
  const paddress = useRef();
  const pcontact = useRef();
  const precommended = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const pFirstName =
    languageValue === "En"
      ? En["page.signUpFirstNamePlaceholder"]
      : Fr["page.signUpFirstNamePlaceholder"];
  const pTitle = languageValue === "En" ? "Title" : "Title";
  const pLastName =
    languageValue === "En"
      ? En["page.signUpLastNamePlaceholder"]
      : Fr["page.signUpLastNamePlaceholder"];
  const pAddress =
    languageValue === "En" ? En["page.AddressInput"] : Fr["page.AddressInput"];
  const pCity =
    languageValue === "En" ? En["page.CityInput"] : Fr["page.CityInput"];
  const pPhone =
    languageValue === "En" ? En["page.PhoneInput"] : Fr["page.PhoneInput"];
  const pCode =
    languageValue === "En"
      ? En["page.PostalCodeInput"]
      : Fr["page.PostalCodeInput"];
  const pCountry = languageValue === "En" ? "Country" : "Pays";

  const changeActiveBtn = (e, refname) => {
    let btns = document.querySelectorAll(".btnLink");
    btns.forEach((btn) => {
      btn.classList.remove("activebtn");
      if (btn == e.target) {
        btn.classList.add("activebtn");
      }
    });
    refname.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const getAllPets = () => {
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        res.data?.data[0]?.isempty === true
          ? setisanimalavailable(false)
          : setisanimalavailable(true);
      })
      .catch((e) => {});
  };
  const getUserdata = () => {
    setShowLoader(true);
    let defaultValues = {};
    AXIOS_GET(GET_PROFILE_URL, token)
      .then((response) => {
        setShowLoader(false);
        let user_info = response.data.data;
        dispatch(UserInfo(user_info));
        dispatch(ProfileAvatar(response.data.data.feature_image));
        dispatch(ProfileFname(response.data.data.first_name));
        dispatch(ProfileLname(response.data.data.last_name));
        defaultValues.first_name = user_info.first_name;
        defaultValues.last_name = user_info.last_name;
        defaultValues.title = user_info.title;
        defaultValues.country = user_info.country;
        defaultValues.email = user_info.email;
        defaultValues.address = user_info.address;
        defaultValues.contact_with_email = user_info.contact_with_email;
        defaultValues.contact_with_sms = user_info.contact_with_sms;
        defaultValues.contact_with_whatsapp = user_info.contact_with_whatsapp;
        defaultValues.city = user_info.city;
        defaultValues.phone = user_info.phone;
        defaultValues.postal_code = user_info.postal_code;
        defaultValues.referant_description = user_info.referant_description;
        reset({ ...defaultValues });
        setShowLoader(false);
        user_info.address === "" ||
        user_info.phone === "" ||
        user_info.city === ""
          ? SetPop(true)
          : SetAnimalPop(true);
      })
      .catch((e) => {
        languageValue === "En"
          ? toast("Something went wrong ")
          : toast("Quelque chose s'est mal passé");
      });
  };
  useEffect(() => {
    getUserdata();
    getAllPets();
  }, []);
  const handleFormUpdate = (data) => {
    setShowLoader(true);
    AXIOS_PUT(
      UPDATE_PROFILE_URL,
      data,
      token,
      languageValue == "En" ? "en" : "fr"
    )
      .then((result) => {
        languageValue === "En"
          ? toast("User Updated Successful!")
          : toast("Mise à jour de l'utilisateur réussie!");
        getUserdata();
        dispatch(UserInfo(result.data.data));
        setShowLoader(false);
      })
      .catch((e) => {
        languageValue === "En"
          ? toast("Something went wrong ")
          : toast("Quelque chose s'est mal passé");
        setShowLoader(false);
      });
  };
  return (
    <RightContainer>
      {ShowPop ? <FirstTimePopUp /> : ""}
      {ShowAnimalPop ? <AnimalPopUp isAvailable={isanimalavailable} /> : ""}
      <ToastContainer />
      <Form onSubmit={handleSubmit(handleFormUpdate)}>
        <div className="LinksHeader">
          <button
            type="button"
            className="activebtn btnLink"
            onClick={(e) => changeActiveBtn(e, pinfo)}
          >
            <Translate Word="page.profile.pinfo" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, paddress)}
          >
            {" "}
            <Translate Word="page.AddressInput" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, pcontact)}
          >
            <Translate Word="page.profile.contactwith" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, precommended)}
          >
            <Translate Word="page.referant.description" />
          </button>
        </div>
        <TitleHeader ref={pinfo}>
          <Translate Word="page.profile.pinfo" />
        </TitleHeader>
        <CatgCard data-catg="1">
          <div className="div">
            <FormGroup className="form-group">
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.FirstNameInput" />{" "}
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pFirstName}
                  {...register("first_name", { required: true })}
                />
                {errors?.first_name?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.firstName.required"></Translate>
                    </Fade>{" "}
                  </Perror>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.LastNameInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pLastName}
                  {...register("last_name", { required: true })}
                />
                {errors?.last_name?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.secondName.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>

            <FormGroup className="form-group">
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.title" />{" "}
                </Label>
                <select
                  className="selectStyles"
                  {...register("title", { required: true })}
                >
                  <option value="">{pTitle}</option>
                  <option value={"Madame"}>
                    <Translate Word="page.general.madame"></Translate>
                  </option>
                  <option value={"Monsieur"}>
                    <Translate Word="page.general.monsieur"></Translate>
                  </option>
                  <option value={"Mademoiselle"}>
                    <Translate Word="page.general.mademoiselle"></Translate>
                  </option>
                </select>

                {errors?.title?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="page.form.title.error"></Translate>
                    </Fade>{" "}
                  </Perror>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.country" />
                </Label>
                <select
                  className="selectStyles"
                  {...register("country", { required: true })}
                >
                  <option value={"Suisse"}>
                    <Translate Word="page.general.suisse"></Translate>
                  </option>
                  <option value={"France"}>
                    <Translate Word="page.general.france"></Translate>
                  </option>
                  <option value={"Italie"}>
                    <Translate Word="page.general.Italie"></Translate>
                  </option>
                  <option value={"Allemagne"}>
                    <Translate Word="page.general.Allemagne"></Translate>
                  </option>
                </select>

                {errors?.country?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="page.form.country.error"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </FormControl>
            </FormGroup>

            <Label>
              {" "}
              <Redspan>*</Redspan>
              <Translate Word="antTable.title.email" />
            </Label>
            <Input
              type="email"
              placeholder="Email"
              disabled
              {...register("email")}
            />
          </div>
        </CatgCard>
        <TitleHeader ref={paddress}>
          <Translate Word="page.AddressInput" />
        </TitleHeader>
        <CatgCard data-catg="2">
          <div>
            <FormGroup className="form-group">
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.AddressInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pAddress}
                  {...register("address", { required: true })}
                />
                {errors?.address?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.address.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.CityInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pCity}
                  {...register("city", { required: true })}
                />
                {errors?.city?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.city.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group">
              <div>
                <Label>
                  {" "}
                  <Redspan>*</Redspan> <Translate Word="page.PhoneInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pPhone}
                  {...register("phone", { required: true })}
                />
                {errors?.phone?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.phone.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Label>
                  {" "}
                  <Redspan>*</Redspan> <Translate Word="page.PostalCodeInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pCode}
                  {...register("postal_code", { required: true })}
                />
                {errors?.postal_code?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.postalCode.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </div>
            </FormGroup>
          </div>
        </CatgCard>
        <TitleHeader ref={pcontact}>
          <Translate Word="page.profile.contactwith" />
        </TitleHeader>
        <CatgCard data-catg="3">
          <div>
            <FormGroup>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.contact_with_email" />
                </Label>
                <select
                  className="selectStyles"
                  {...register("contact_with_email", { required: true })}
                >
                  <option value={"yes"}>
                    <Translate Word="page.general.yes"></Translate>
                  </option>
                  <option value={"no"}>
                    <Translate Word="page.general.no"></Translate>
                  </option>
                </select>
                {errors?.contact_with_email?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.secondName.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.contact_with_sms" />
                </Label>
                <select
                  className="selectStyles"
                  {...register("contact_with_sms", { required: true })}
                >
                  <option value={"yes"}>
                    <Translate Word="page.general.yes"></Translate>
                  </option>
                  <option value={"no"}>
                    <Translate Word="page.general.no"></Translate>
                  </option>
                </select>
                {errors?.contact_with_email?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.secondName.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )}
              </FormControl>
              {hostname != "evc.clinic" && (
                <FormControl fullWidth>
                  <Label>
                    <Redspan>*</Redspan>

                    <Translate Word="page.contact_with_whatsapp" />
                  </Label>
                  <select
                    className="selectStyles"
                    {...register("contact_with_whatsapp", { required: false })}
                  >
                    <option value={"yes"}>
                      <Translate Word="page.general.yes"></Translate>
                    </option>
                    <option value={"no"}>
                      <Translate Word="page.general.no"></Translate>
                    </option>
                  </select>
                  {errors?.contact_with_whatsapp?.type === "required" ? (
                    <Perror>
                      <Fade bottom>
                        <Translate Word="errors.form.secondName.required"></Translate>
                      </Fade>
                    </Perror>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
            </FormGroup>
          </div>
        </CatgCard>
        <TitleHeader ref={precommended}>
          {" "}
          <Translate Word="page.referant.description" />
        </TitleHeader>
        <CatgCard data-catg="4">
          <div>
            <FormGroup>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.referant.description" />
                </Label>
                <select
                  className="selectStyles"
                  {...register("referant_description", { required: true })}
                >
                  {hostname != "evc.clinic" && (
                    <>
                      <option value="" disabled selected>
                        <Translate Word="page.referant.select"></Translate>
                      </option>
                      <option value={"Recommandé par un ami"}>
                        <Translate Word="page.referant.byfriend"></Translate>
                      </option>
                      <option value={"Google Adwords"}>
                        <Translate Word="page.referant.bygads"></Translate>
                      </option>
                      <option value={"Local / Search"}>
                        <Translate Word="page.referant.bylocal"></Translate>
                      </option>
                      <option value={"Vétérinaire traitant"}>
                        <Translate Word="page.referant.byvet1"></Translate>
                      </option>{" "}
                      <option value={"Facebook / Instagram"}>
                        <Translate Word="page.referant.byfbc"></Translate>
                      </option>
                      <option value={"Vétérinaire Fermé"}>
                        <Translate Word="page.referant.byvt2"></Translate>
                      </option>{" "}
                    </>
                  )}
                  {hostname == "evc.clinic" && (
                    <>
                      <option value="" disabled selected>
                        <Translate Word="page.referant.select"></Translate>
                      </option>
                      <option value="Google">
                        <Translate Word="page.referant.Google"></Translate>
                      </option>
                      <option value={"Facebook / Instagram"}>
                        <Translate Word="page.referant.byfbc"></Translate>
                      </option>
                      <option value={"Vétérinaire traitant"}>
                        <Translate Word="page.referant.byvet1"></Translate>
                      </option>
                      <option value={"Local.ch"}>
                        <Translate Word="page.referant.Localch"></Translate>
                      </option>
                      <option value={"Recommandé par un proche"}>
                        <Translate Word="page.referant.Relatives"></Translate>
                      </option>
                    </>
                  )}
                </select>
              </FormControl>
              <FormControl fullWidth />
              <FormControl fullWidth />
            </FormGroup>
          </div>
        </CatgCard>

        {/* <TitleHeader>
          <Translate Word="page.profile.action" />
        </TitleHeader> */}

        <FormAction className="web-action">
          <Button >
            <Translate Word="page.profile.saveChanges.btn" />
          </Button>
        </FormAction>
        <FormAction className="web-action">
          <Pinstead onClick={() => dispatch(UpdateUserPassword())}>
            <Translate Word="page.profile.changePassword.btn" />
          </Pinstead>
        </FormAction>
       < MoboActionButton>
       <FormAction>
          <Button>
            <Translate Word="page.profile.saveChanges.btn" />
          </Button>
        </FormAction>
        <FormAction>
          <ChangePasswordButton type="button" onClick={() => dispatch(UpdateUserPassword())}>
            <Translate Word="page.profile.changePassword.btn" />
          </ChangePasswordButton>
        </FormAction>
       </MoboActionButton>

      </Form>
      {showUpdateForm ? <NewPassword /> : ""}
      {showLoader ? <PageLoader /> : ""}
    </RightContainer>
  );
};

export default RightProfileForm;

const RightContainer = styled.div`
  flex: 2;
  padding: 20px;
  padding-top: 0px;
  position: relative;
  background: #f1f4f8 !important;
  @media screen and (max-width: 500px) {
    padding: 10px;
    padding-top: 0px;
  }
`;
const CatgCard = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  margin-bottom: 40px;
  padding: 24px;
`;

const Input = styled.input`
  width: 100%;
  padding: 4px 10px;
  font-size: 13px;
  border: 1px solid #e4e6e9;
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 14px;
  outline: none;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const InputGroup = styled(Input)``;
const Form = styled.form``;
const FormAction = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  padding: 6px 16px;
  height: 42px;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
`;
const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px !important;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const Label = styled.label`
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  display: block;
  width: 100%;
`;
const LabelCheckBox = styled(Label)`
  display: flex;
  gap: 8px;
`;
const Pinstead = styled.div`
  text-align: center;
  padding-top: 20px;
  color: #76afc8;
  font-size: 14px;
  cursor: pointer;
`;
const Redspan = styled.span`
  color: red;
  margin-right: 8px;
`;
const Perror = styled.p`
  color: #d03333e0;
  font-size: 14px;
  margin-bottom: 14px;
`;

const TitleHeader = styled.h1`
  font-weight: 500;
  font-size: 24px;
  color: #1c2232;
  margin-bottom: 14px;
`;
const MoboActionButton = styled.div`
 display:none;
 @media (max-width: 600px) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  bottom: 67px;
  width: 110%;
  background:#f1f4f8 !important;
  padding: 7px;
  margin-left: -40px;
  padding-left: 40px;
  z-index: 2;
 }
 
`

const ChangePasswordButton = styled.button`
  padding: 6px 16px;
  height: 42px;
  cursor: pointer;
  color: #fff;
  background: transparent;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: 2px solid #76afc8;
  color:#76afc8
`;
