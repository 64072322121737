import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../assets/images/banner_right.jpg";
import { useNavigate, useParams } from "react-router-dom";
import VerifyEmailContainer from "../components/VerifyContainer";
import { AXIOS_GET } from "../config/axios";
import BannerEvc from "../assets/images/evcBg.png";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { id, hash } = useParams();
  let hostname = window.location.hostname.split(".").slice(-2).join(".");
  const URL = `/verify-email/${id}/${hash}`;
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  let bgImage = Banner;
  if (hostname == "evc.clinic") {
    bgImage = BannerEvc;
  } else if (hostname == "vetmidi.org") {
    bgImage = Banner;
  } else {
    bgImage = Banner;
  }
  const handleVerify = () => {
    AXIOS_GET(`${URL}`, token)
      .then((result) => {
        localStorage.setItem("user_verified", true);
        navigate("/dashboard", { replace: true });
      })
      .catch((e) => {
        navigate("/confirm_Email");
      });
  };
  useEffect(() => {
    if (token?.length < 10) {
      navigate("/confirm_Email");
    } else {
      handleVerify();
    }
  }, [token]);

  return (
    <Container style={{ backgroundImage: `url(${bgImage})` }}>
      <LeftContainer>
        <VerifyEmailContainer />
      </LeftContainer>
      {hostname != "evc.clinic" && <Overlay />}
      {hostname == "evc.clinic" && (
        <RightContainer
          style={{ backgroundImage: `url(${bgImage})` }}
        ></RightContainer>
      )}
    </Container>
  );
};

export default VerifyEmail;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  color: white;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000085;
`;

const LeftContainer = styled(Overlay)`
  width: 500px;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
  overflow-y: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
  @media screen and (max-width: 500px) {
    width: 100vw;
  }
`;

const RightContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 0;
  color: white;
  @media screen and (max-width: 500px) {
    width: 0;
  }
`;
