import React, { useRef, useState, useEffect } from "react";
import "../../../styles/PetsInfo.scss";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import AddImage from "../../../assets/images/addAnimal.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADD_PET_FILE,
  GET_DOWNLOAD_FILE_URL,
  GET_PET_FILE_URL,
  GET_PET_URL,
  UPDATE_PET_URL,
} from "../../../helper/Url";
import { AXIOS_GET, AXIOS_PUT } from "../../../config/axios";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import Translate from "../../../config/Translate";
import AddIcon from "@mui/icons-material/Add";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useSelector } from "react-redux";
import MoonLoader from "react-spinners/ClipLoader";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import { Dashboard } from "@uppy/react";
import Url from "@uppy/url";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/provider-views/dist/style.css";
import XHRUpload from "@uppy/xhr-upload";
import { Base_Url } from "../../../config/variables";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileSaver from "file-saver";

const AddNew = () => {
  const languageValue = useSelector(selectLanguage);
  const [isFile, setisFile] = useState(false);
  const [isDownload, setisDownload] = useState(false);
  const navigate = useNavigate();
  const [PhotoFd, setPhotoFd] = useState(null);
  const [imgUrlPrev, setimgUrlPrev] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequesting, setisRequesting] = useState(false);
  const [isinsuredSelect, setinsuredSelect] = useState(0);
  const [PetType, setPetType] = useState("");
  const [petDataResponse, setpetDataResponse] = useState([]);
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [filesData, setfilesData] = useState([]);
  const [refName, setrefName] = useState();
  const [refBirth, setrefBirth] = useState();
  const [refType, setrefType] = useState();
  const [refBreed, setrefBreed] = useState();
  const [refSex, setrefSex] = useState();
  const [refColor, setrefColor] = useState();
  const [refmicroship, setrefmicroship] = useState();
  const [refIdentDate, setrefIdentDate] = useState();
  const [refWeight, setrefWeight] = useState();
  const [refAliName, setrefAliName] = useState();
  const [refVacine, setrefVacine] = useState();
  const [refRage, setrefRage] = useState();
  const [refInsurance, setrefInsurance] = useState();
  const [refgoOutSide, setrefgoOutSide] = useState();
  const [refisInsured, setrefisInsured] = useState();
  const [refsterilise, setrefsterilise] = useState();
  let hostname = window.location.hostname.split(".").slice(-2).join(".");

  let uploadFiledata = [];
  let data = {};
  const uppy = new Uppy({
    meta: { type: "document" },
    restrictions: {
      maxFileSize: 4 * 1024 * 1024,
      allowedFileTypes: [".pdf", ".doc", ".docx"],
    },
    locale: {
      strings: {
        dropPasteFiles:
          languageValue === "En"
            ? "Put your files here, they will be uploaded and sent to the clinic or %{browseFiles}"
            : "Déposez vos fichiers, ils seront téléchargés et envoyés à la la clinique ou %{browseFiles}",
        browseFiles: languageValue === "En" ? "browse" : "parcourir",
      },
    },
  }).use(Webcam);
  uppy.use(Dropbox, {
    companionUrl: Base_Url,
  });
  uppy.use(GoogleDrive, {
    companionUrl: Base_Url,
  });
  uppy.use(Url, { companionUrl: Base_Url });

  uppy.setOptions({
    target: Dashboard,
    plugins: ["GoogleDrive", "Dropbox", "Url"],
  });
  uppy.use(XHRUpload, {
    endpoint: `${ADD_PET_FILE}${encodeURIComponent(id)}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  uppy.on("complete", (result) => {
    setTimeout(() => {
      backPage();
    }, 4000);
  });
  const getpetFiles = () => {
    AXIOS_GET(`${GET_PET_FILE_URL}${id}`, token)
      .then((res) => {
        setfilesData(res.data.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getpetDadata();
    getpetFiles();
  }, []);

  const downloadFile = (path, fileName) => {
    setisDownload(true);
    fetch(GET_DOWNLOAD_FILE_URL + path, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setisDownload(false);
        FileSaver.saveAs(blob, fileName);
      });
  };

  const getUploadUrl = (e) => {
    const [file] = e.target.files;
    if (file) {
      let reader = new FileReader();
      reader.onload = function () {
        setPhotoFd(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
      setimgUrlPrev(URL.createObjectURL(file));
    }
  };

  const getpetDadata = () => {
    setIsLoading(true);
    AXIOS_GET(`${GET_PET_URL}${id}`, token)
      .then((res) => {
        if (res.data.data) {
          setpetDataResponse(res.data.data);

          setrefsterilise(res.data.data.sterilise);
          setrefName(res.data.data.name ? res.data.data.name : "N/A");
          setrefBirth(
            res.data.data.birth_date
              ? res.data.data.birth_date.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  "$3-$1-$2"
                )
              : ""
          );
          setrefIdentDate(
            res.data.data.identification_date
              ? res.data.data.identification_date.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  "$3-$1-$2"
                )
              : ""
          );
          setrefVacine(
            res.data.data.relance_maladies
              ? res.data.data.relance_maladies.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  "$3-$1-$2"
                )
              : ""
          );
          setrefRage(
            res.data.data.relance_rage
              ? res.data.data.relance_rage.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  "$3-$1-$2"
                )
              : ""
          );
          setrefType(res.data.data.species ? res.data.data.species : "N/A");
          setPetType(res.data.data.species);
          setrefBreed(res.data.data.race ? res.data.data.race : "N/A");
          setrefSex(res.data.data.sex ? res.data.data.sex : "N/A");
          setrefColor(res.data.data.color ? res.data.data.color : "N/A");
          setimgUrlPrev(
            res.data.data.web_image ? res.data.data.web_image : AddImage
          );
          setPhotoFd(res.data.data.web_image ? res.data.data.web_image : "");
          setrefmicroship(
            res.data.data.microship ? res.data.data.microship : "N/A"
          );
          setrefWeight(res.data.data.weight ? res.data.data.weight : "N/A");
          setrefisInsured(
            res.data.data.insurance_desc ? res.data.data.insurance_desc : "N/A"
          );
          setinsuredSelect(res.data.data.insurance_desc);
          setrefAliName(
            res.data.data.alimentation ? res.data.data.alimentation : "N/A"
          );
          setrefInsurance(
            res.data.data.insurance_name ? res.data.data.insurance_name : "N/A"
          );
          setrefgoOutSide(
            res.data.data.mode_de_vie ? res.data.data.mode_de_vie : "NON"
          );
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const backPage = () => {
    navigate("/dashboard/pets");
  };
  const switchTab = (e) => {
    document.querySelectorAll(".btn_form_switch").forEach((btn) => {
      if (btn === e.target) {
        btn.classList.add("activebtn");
        getpetDadata();
      } else {
        btn.classList.remove("activebtn");
        getpetDadata();
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    data = {
      name: refName,
      birth_date: refBirth,
      species: refType,
      race: refBreed,
      sex: refSex,
      photo: PhotoFd,
      files: uploadFiledata,
      microship: refmicroship,
      weight: refWeight,
      identification_date: refIdentDate,
      alimentation: refAliName,
      insurance_name: refInsurance ? refInsurance : "N/A",
      color: refColor,
      sterilise: refsterilise,
      relance_maladies: refVacine,
      relance_rage: refRage,
      insurance_desc: refisInsured,
      mode_de_vie: refgoOutSide ? refgoOutSide : "NON",
    };
    // if (refmicroship.length < 15) {
    //   languageValue === "En"
    //     ? toast(
    //         "Microchip number is required and must be made up of 15 numbers"
    //       )
    //     : toast(
    //         "Le numéro de puce est obligatoire et doit être composé de 15 chiffres"
    //       );
    // } else {
    setisRequesting(true);
    AXIOS_PUT(`${UPDATE_PET_URL}${encodeURIComponent(id)}`, data, token)
      .then((res) => {
        setisRequesting(false);
        languageValue === "En"
          ? toast("Pet updated successful")
          : toast("Animal mise à jour avec succès");
      })
      .catch((e) => {
        setisRequesting(false);
        if (e.response?.data?.errors) {
          e.response.data.errors.forEach((error) => toast(error.message));
        } else {
          toast("Something went wrong");
        }
      });
  };
  return (
    <div className="pet_info_container">
      <ToastContainer />
      <div className="pet_info">
        <div className="pet_info_back" onClick={backPage}>
          <Back></Back>
          <span>
            <Translate Word="page.pets.back"></Translate>
          </span>
        </div>
        <div className="pet_info_body">
          <h1>
            <Translate Word="page.pets.editPet"></Translate>
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="imageUploader">
              <div className="img_container">
                {imgUrlPrev ? (
                  <img src={imgUrlPrev} alt="" />
                ) : (
                  <img src={AddImage} alt="" />
                )}
              </div>
              <label htmlFor="petimageId">
                <h4>
                  {" "}
                  <AddIcon></AddIcon>{" "}
                  <Translate Word="page.pets.editImage"></Translate>
                </h4>
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={getUploadUrl}
                id="petimageId"
                hidden
              />
              <div className="frmSwitcher">
                <button
                  type="button"
                  className="btn_form_switch activebtn"
                  onClick={(e) => {
                    setisFile(false);
                    switchTab(e);
                  }}
                >
                  <Translate Word="page.pet.general" />
                </button>
                <button
                  type="button"
                  className="btn_form_switch"
                  onClick={(e) => {
                    setisFile("file");
                    switchTab(e);
                  }}
                >
                  <Translate Word="page.pet.files" />
                </button>
                <button
                  type="button"
                  className="btn_form_switch"
                  onClick={(e) => {
                    setisFile("upload");
                    switchTab(e);
                  }}
                >
                  <Translate Word="page.pet.upload" />
                </button>
              </div>
            </div>
            {!isFile && (
              <>
                {petDataResponse.length <= 0 ? (
                  <SkeletonTheme baseColor="#f7f7f7" highlightColor="#d3d3d3">
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                    <div className="singleInput">
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) : (
                  <>
                    <div className="columnContainer">
                      <div className="column">
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.Petname"></Translate>
                          </label>
                          <input
                            type="text"
                            id="PetName"
                            required
                            value={refName}
                            onChange={(e) => setrefName(e.target.value)}
                          />
                        </div>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.Type"></Translate>
                          </label>
                          <select
                            name=""
                            id=""
                            value={refType}
                            required
                            onChange={(e) => setrefType(e.target.value)}
                          >
                            <option value="" disabled selected>
                              <Translate Word="page.pets.typeOfpet"></Translate>
                            </option>
                            {hostname != "evc.clinic" && (
                              <>
                                <option value="CT">
                                  <Translate Word="page.type.CT"></Translate>
                                </option>
                                <option value="CN">
                                  <Translate Word="page.type.CN"></Translate>
                                </option>
                                <option value="LP">
                                  <Translate Word="page.type.LP"></Translate>
                                </option>
                                <option value="GP">
                                  <Translate Word="page.type.GP"></Translate>
                                </option>
                                <option value="OI">
                                  <Translate Word="page.type.OI"></Translate>
                                </option>
                                <option value="HAM">
                                  <Translate Word="page.type.HAM"></Translate>
                                </option>
                                <option value="NAC">
                                  <Translate Word="page.type.NAC"></Translate>
                                </option>
                                <option value="REP">
                                  <Translate Word="page.type.REP"></Translate>
                                </option>
                              </>
                            )}
                            {hostname == "evc.clinic" && (
                              <>
                                <option value="CT">
                                  <Translate Word="page.type.CT"></Translate>
                                </option>
                                <option value="CN">
                                  <Translate Word="page.type.CN"></Translate>
                                </option>
                                <option value="LP">
                                  <Translate Word="page.type.LP"></Translate>
                                </option>
                                <option value="NAC">
                                  <Translate Word="page.type.NAC"></Translate>
                                </option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="singleInput">
                          <label htmlFor="">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.Spayed"></Translate>
                          </label>
                          <select
                            value={refsterilise}
                            onChange={(e) => setrefsterilise(e.target.value)}
                            required
                          >
                            <option value="" disabled selected>
                              <Translate Word="page.petData.select" />
                            </option>
                            <option value="OUI">
                              <Translate Word="page.general.yes"></Translate>
                            </option>
                            <option value="NON">
                              <Translate Word="page.general.no"></Translate>
                            </option>
                          </select>
                        </div>
                        {hostname != "evc.clinic" && (
                          <>
                            <div className="singleInput">
                              <label htmlFor="petName">
                                <Translate Word="page.pets.VacinationDate"></Translate>
                              </label>
                              <input
                                type="date"
                                id="PetName"
                                value={refVacine}
                                onChange={(e) => setrefVacine(e.target.value)}
                                placeholder="Vacination Date"
                              />
                            </div>
                            <div className="singleInput">
                              <label htmlFor="petName">
                                <Translate Word="page.pets.IdentificationDate"></Translate>
                              </label>
                              <input
                                type="date"
                                id="PetName"
                                value={refIdentDate}
                                onChange={(e) =>
                                  setrefIdentDate(e.target.value)
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="column">
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>{" "}
                            <Translate Word="page.pets.birth"></Translate>
                          </label>
                          <input
                            type="date"
                            id="PetName"
                            required
                            value={refBirth}
                            onChange={(e) => setrefBirth(e.target.value)}
                            placeholder="Date of birth"
                          />
                        </div>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.breed"></Translate>
                          </label>
                          <input
                            type="text"
                            id="PetName"
                            required
                            value={refBreed}
                            onChange={(e) => setrefBreed(e.target.value)}
                            placeholder="Enter Breed"
                          />
                        </div>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Translate Word="page.pets.Color"></Translate>
                          </label>
                          <input
                            type="text"
                            id="PetName"
                            value={refColor}
                            onChange={(e) => setrefColor(e.target.value)}
                            placeholder="Enter color"
                          />
                        </div>
                        {hostname != "evc.clinic" && (
                          <div className="singleInput">
                            <label htmlFor="petName">
                              <Translate Word="page.pets.RabbiesBoaster"></Translate>
                            </label>
                            <input
                              type="date"
                              id="PetName"
                              value={refRage}
                              onChange={(e) => setrefRage(e.target.value)}
                              placeholder="Rabbies Vacinnation"
                            />
                          </div>
                        )}

                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Translate Word="page.pets.Microship"></Translate>
                          </label>

                          <input
                            type="number"
                            id="PetName"
                            disabled
                            step="any"
                            title="Microchip number"
                            value={refmicroship}
                            onChange={(e) => setrefmicroship(e.target.value)}
                            placeholder={
                              refmicroship === "N/A"
                                ? languageValue === "En"
                                  ? "The clinic will enter the microship number"
                                  : "votre clinique complètera le numéro de microship"
                                : "Microchip"
                            }
                          />
                        </div>
                      </div>
                      <div className="column">
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.Weight"></Translate>
                          </label>
                          <input
                            type="number"
                            id="PetName"
                            required
                            value={refWeight}
                            onChange={(e) => setrefWeight(e.target.value)}
                            placeholder="Weight"
                          />
                        </div>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.sex"></Translate>
                          </label>
                          <select
                            type="text"
                            value={refSex}
                            onChange={(e) => setrefSex(e.target.value)}
                            required
                            placeholder="Enter sex"
                          >
                            <option value="" disabled selected>
                              <Translate Word="page.petData.select" />
                            </option>
                            <option value="M">
                              <Translate Word="page.general.male"></Translate>
                            </option>
                            <option value="F">
                              <Translate Word="page.general.female"></Translate>
                            </option>
                          </select>
                        </div>
                        {hostname != "evc.clinic" && (
                          <div className="singleInput">
                            <label htmlFor="petName">
                              <Translate Word="page.pets.Alimentation"></Translate>
                            </label>
                            <input
                              type="text"
                              id="PetName"
                              value={refAliName}
                              onChange={(e) => setrefAliName(e.target.value)}
                              placeholder="Alimentation's name"
                            />
                          </div>
                        )}

                        {refType != "CT" && hostname != "evc.clinic" && (
                          <div className="singleInput">
                            <label htmlFor="">
                              <Translate Word="page.pets.Goesoutside"></Translate>
                            </label>
                            <select
                              value={refgoOutSide}
                              onChange={(e) => setrefgoOutSide(e.target.value)}
                            >
                              <option value="" disabled selected>
                                <Translate Word="page.petData.select" />
                              </option>
                              <option value="OUI">
                                <Translate Word="page.general.yes"></Translate>
                              </option>
                              <option value="NON">
                                <Translate Word="page.general.no"></Translate>
                              </option>
                            </select>
                          </div>
                        )}

                        <div className="singleInput">
                          <label htmlFor="">
                            <Redspan>*</Redspan>
                            <Translate Word="page.pets.IsInsured"></Translate>
                          </label>
                          <select
                            value={refisInsured}
                            required
                            onChange={(e) => {
                              setinsuredSelect(e.target.value);
                              setrefisInsured(e.target.value);
                            }}
                          >
                            <option value="" disabled selected>
                              <Translate Word="page.petData.select" />
                            </option>
                            <option value="OUI">
                              <Translate Word="page.general.yes"></Translate>
                            </option>
                            <option value="NON">
                              <Translate Word="page.general.no"></Translate>
                            </option>
                          </select>
                        </div>
                        {isinsuredSelect != "NON" ? (
                          <div className="singleInput">
                            <label htmlFor="petName">
                              <Translate Word="page.pets.Insurancename"></Translate>
                            </label>
                            <input
                              type="text"
                              id="PetName"
                              placeholder="Insurance name"
                              value={refInsurance}
                              onChange={(e) => setrefInsurance(e.target.value)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="actionbtn">
                  <button type="button" className="cancel" onClick={backPage}>
                    <Translate Word="page.pet.cancel" />
                  </button>
                  {isRequesting ? (
                    <button type="button" className="save">
                      <MoonLoader color="#ffffff" size={20} />
                    </button>
                  ) : (
                    <button className="save">
                      <Translate Word="page.pet.save" />
                    </button>
                  )}
                </div>
              </>
            )}

            {isFile === "upload" && (
              <Dashboard height={400} uppy={uppy} plugins={[]} />
            )}
            {isFile === "file" && (
              <ul className="filesLists">
                {filesData?.length ? (
                  <>
                    {" "}
                    {filesData.map((filed) => {
                      return (
                        <li key={filed.pet_id}>
                          <InsertDriveFileIcon />
                          <h6>{filed.filename}</h6>
                          <button
                            className="downloadBtn"
                            type="button"
                            onClick={() =>
                              downloadFile(filed.file_path, filed.filename)
                            }
                          >
                            <DownloadIcon></DownloadIcon>
                          </button>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li>
                    <div></div>
                    <h6>
                      <Translate Word="page.pet.Nofiles" />
                    </h6>
                    <div></div>
                  </li>
                )}
              </ul>
            )}
          </form>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default AddNew;

const Redspan = styled.span`
  color: red;
  margin-right: 8px;
`;
